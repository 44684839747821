<template>
  <body class="overflow-hidden">
    <v-app-bar
      class="mb-2"
      absolute
      color="white"
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"
        ><fai icon="bars"
      /></v-app-bar-nav-icon>

      <v-toolbar-title
        ><v-img :src="$store.getters.get_logo"></v-img
      ></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="$router.push('/notifications/' + 1)">
        <v-badge :content="$store.getters.get_notice.toString()"
          ><fai icon="bell"
        /></v-badge>
      </v-btn>

      <v-btn icon @click.stop="$store.dispatch('logout')">
        <fai icon="lock" />
      </v-btn>
    </v-app-bar>

    <v-container id="scrolling-techniques-7" class="mt-6 overflow-y-auto">
      <!--
  START OF WEB PAGE
      -->
      <div class="mt-6 con" :style="{ height: '100%', width: '100%' }">
        <div class="form mt-3">
          <v-card class="pa-3">
            <v-chip large outlined
              ><fai icon="truck" />&nbsp;New shipping company</v-chip
            >
            <div class="mt-3">
              <v-text-field
                v-model="c_name"
                outlined
                label="Company Name"
                type="text"
              ></v-text-field>
            </div>

            <div>
              <v-text-field
                v-model="c_mobile"
                outlined
                label="Company Mobile"
                type="number"
              ></v-text-field>
            </div>

            <div>
              <v-text-field
                v-model="c_email"
                outlined
                label="Company Email"
                type="email"
              ></v-text-field>
            </div>

            <div>
              <v-text-field
                v-model="store_id"
                outlined
                label="Seller Store ID"
                type="text"
                placeholder="If a seller is involved"
              >
                <template v-slot:append>
                  <v-btn small color="#fe7b00" dark @click.stop="openSellerBox"
                    >Verify</v-btn
                  >
                </template>
              </v-text-field>
            </div>

            <v-dialog
              max-width="300px"
              v-model="seller_info"
              :style="{ position: 'relative' }"
            >
              <v-card class="seller_box">
                <div
                  v-if="seller_info_loading"
                  class="d-flex justify-center pt-2"
                >
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <v-card-title>Seller Information</v-card-title>
                <div v-if="seller_data !== null">
                  <div class="b_cont">
                    <div class="sm_r_box"></div>
                    <div>{{ seller_data.shop_name }}</div>
                  </div>

                  <div class="b_cont">
                    <div class="sm_r_box"></div>
                    <div>
                      {{ seller_data.firstname }}&nbsp;{{
                        seller_data.middlename
                      }}&nbsp;{{ seller_data.lastname }}
                    </div>
                  </div>

                  <div class="b_cont">
                    <div class="sm_r_box"></div>
                    <div>{{ seller_data.email }}</div>
                  </div>

                  <div class="b_cont">
                    <div class="sm_r_box"></div>
                    <div>{{ seller_data.shop_description }}</div>
                  </div>

                  <div class="b_cont">
                    <div class="sm_r_box"></div>
                    <div>{{ seller_data.phone }}</div>
                  </div>
                </div>
                <v-btn
                  icon
                  :style="{
                    position: 'absolute',
                    top: '3px',
                    right: '3px',
                  }"
                  @click.stop="seller_info = false"
                  ><fai icon="close"
                /></v-btn>
              </v-card>
            </v-dialog>

            <div>
              <v-btn @click="selectImage" :color="theme" width="100%" text
                ><fai icon="image" />&nbsp;Select Company Logo</v-btn
              >
            </div>

            <div class="mt-1">
              <v-card width="150" height="150">
                <img
                  width="100%"
                  height="100%"
                  src=""
                  id="company_image_preview"
                  alt=""
                />
                <input
                  @change="onImageSelected"
                  id="company_logo_image"
                  type="file"
                  accept="image/*"
                  :style="{ visibility: 'hidden' }"
                />
              </v-card>
            </div>

            <div class="mt-2">
              <v-btn
                @click="addCompany"
                :color="theme"
                width="100%"
                rounded
                dark
                ><fai icon="truck" />&nbsp;Add Company</v-btn
              >
            </div>
          </v-card>
        </div>

        <div class="list pa-3">
          <ShippingSVG1 v-show="companies.length < 1" />
          <div class="c_cont">
            <v-card
              v-for="(item, index) in companies"
              :key="index"
              class="cont"
            >
              <div
                :style="{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }"
              >
                <v-avatar size="80">
                  <v-img
                    :src="
                      $store.getters.get_api + 'fetchCompanyPic/' + item.logo
                    "
                  ></v-img>
                </v-avatar>
                <div class="pa-2" :style="{ textAlign: 'center' }">
                  <span><fai icon="phone" /></span>
                  {{ item.call_center }}
                </div>
                <div class="pa-2" :style="{ textAlign: 'center' }">
                  <span><fai icon="key" /></span>
                  {{ item.access_pin }}
                </div>
              </div>
              <v-divider></v-divider>
              <v-card-text>{{ item.name }}</v-card-text>
              <v-card-text>{{ item.email }}</v-card-text>
              <v-card-actions
                :style="{ display: 'flex', justifyContent: 'space-between' }"
              >
                <v-switch
                  color="primary"
                  value
                  @change="updateStatus(item.id, index)"
                  :loading="statusLoader"
                  :input-value="item.status === 1 ? true : false"
                >
                  <template v-slot:label> Active Status </template>
                </v-switch>

                <v-btn @click.stop="editCompany(index)" icon
                  ><fai icon="edit"
                /></v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>

        <!--
  EDIT SHIPPING DIALOG
  -->
        <v-dialog v-model="pwddialog" persistent fullscreen>
          <v-card class="gen-pwd_dialog">
            <v-card-title class="text-h5 grey lighten-2">
              Generate Password
            </v-card-title>
            <div class="text-caption text-center py-10" v-if="company_for_edit">
              Generate password for <strong>{{ company_for_edit.name }}</strong>
            </div>
            <div class="text-center py-4">
              <span
                ><strong>{{ generated_pwd }}</strong></span
              >
            </div>
            <div class="py-4 d-flex justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
                v-show="geneprogress"
              >
              </v-progress-circular>
            </div>

            <v-card-actions class="d-flex justify-center">
              <v-btn @click.stop="generate" color="green" dark outlined
                >Generate</v-btn
              >
              <v-btn
                @click="
                  pwddialog = !pwddialog;
                  generated_pwd = undefined;
                "
                color="red"
                dark
                outlined
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--
  EDIT SHIPPING DIALOG
  -->
      </div>
      <!--
  END OF WEB PAGE
      -->
    </v-container>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
      dark
      color="#fe7b00"
    >
      <v-list nav dense>
        <div
          class="d-flex justify-start"
          :style="{ fontSize: '50px', color: '#282c2e' }"
        >
          <fai icon="dashboard" />
          <span
            class="title mx-2 mt-4"
            :style="{ color: '#282c2e', fontStyle: 'italic' }"
            >welcome admin</span
          >
        </div>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item
            v-for="(item, index) in sideMenu"
            :key="index"
            @click="switchMenu(item.name)"
          >
            <v-list-item-icon
              ><fai :icon="item.icon" class="mt-1"
            /></v-list-item-icon>
            <v-list-item-content>{{ item.name }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-overlay v-show="overlay">
      <v-progress-circular
        v-show="progress"
        indeterminate
        :color="fill"
      ></v-progress-circular>
      <v-alert :type="alert_type" shaped v-show="alert_box">
        {{ alert_message }}
        <template v-slot:close
          ><v-btn icon @click.stop="hideOverlay"><fai icon="close" /></v-btn
        ></template>
      </v-alert>
    </v-overlay>
  </body>
</template>

<script>
import ShippingSVG1 from "../components/ShippingSVG1";
export default {
  data: () => {
    return {
      //
      statusLoader: false,
      //
      overlay: false,
      progress: false,
      alert_box: false,
      alert_type: undefined,
      alert_message: undefined,
      drawer: false,
      selectedMenu: "Home",
      sideMenu: [
        { name: "Home", icon: "home" },
        { name: "Admin", icon: "user-circle" },
        { name: "Users", icon: "user-group" },
        { name: "Products", icon: "tasks" },
        { name: "Settings", icon: "gear" },
        { name: "Transactions", icon: "credit-card" },
        { name: "Promotions", icon: "star" },
        { name: "Orders", icon: "book" },
      ],
      theme: "#fe7b00",
      c_mobile: undefined,
      c_name: undefined,
      c_email: undefined,
      loader: false,
      companies: [],
      store_id: "",
      fill: "",
      seller_info: false,
      seller_info_loading: false,
      seller_data: {},

      company_for_edit: undefined,
      company_for_edit_index: undefined,
      pwddialog: false,
      generated_pwd: undefined,
      geneprogress: false,
      /**
       * TEST DATA
       */
    };
  },
  components: {
    ShippingSVG1,
  },
  methods: {
    editCompany(index) {
      this.company_for_edit = this.companies[index];
      this.company_for_edit_index = index;
      //OPEN THE GENERATE BUTTON;
      this.pwddialog = true;
    },
    generate() {
      this.geneprogress = true;
      this.axios({
        url: `${this.$store.getters.get_api}shipping/update_pwd_shipping/${this.company_for_edit.id}`,
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.$store.getters.get_token}`,
        },
      })
        .then((res) => {
          console.log(res);
          if (!res.data.error) {
            this.geneprogress = false;
            this.generated_pwd = res.data.message;
            this.companies[this.company_for_edit_index].access_pin =
              res.data.message;
            //this.company_for_edit = undefined;
            //this.company_for_edit_index = undefined;
          } else {
            this.pwddialog = false;
            this.$store.commit("set_error", true);
            this.$store.commit("set_success", false);
            this.$store.commit("set_progress", false);
            this.$store.commit("set_message", res.data.message);
            this.$store.commit("set_show", true);
          }
        })
        .catch((e) => {
          //console.log(e)
          this.pwddialog = false;
          this.geneprogress = false;
          this.$store.commit("set_error", true);
          this.$store.commit("set_success", false);
          this.$store.commit("set_progress", false);
          this.$store.commit("set_message", "An error occured");
          this.$store.commit("set_show", true);
          return e;
        });
    },
    openSellerBox() {
      this.seller_info = true;
      this.seller_info_loading = true;
      this.axios({
        url: this.$store.getters.get_api + "verifyShop/" + this.store_id,
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.$store.getters.get_token}`,
        },
      })
        .then((res) => {
          const data = res.data;
          if (!data.error) {
            this.seller_info_loading = false;
            this.seller_data = data.message;
          }
        })
        .catch((error) => {
          return error;
        });
    },
    updateStatus(company, index) {
      this.statusLoader = true;
      this.axios({
        url: this.$store.getters.get_api + "changeStatus/" + company,
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.$store.getters.get_token}`,
        },
      })
        .then((res) => {
          const data = res.data;
          //console.log(data)
          if (!data.error) {
            this.statusLoader = false;
          } else {
            this.companies[index].status = !this.companies[index].status;
            this.statusLoader = false;
          }
        })
        .catch((e) => {
          this.companies[index].status = !this.companies[index].status;
          this.statusLoader = false;
          return e;
        });
    },
    hideOverlay() {
      this.overlay = false;
      this.alert_box = false;
      this.progress = false;
    },
    fetchCompany() {
      this.axios({
        url: this.$store.getters.get_api + "fetchAllCompanies",
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.$store.getters.get_token}`,
        },
      })
        .then((res) => {
          const data = res.data;
          // console.log(data)
          if (!data.error) {
            this.companies = data.message;
          }
        })
        .catch((e) => {
          return e;
        });
    },
    addCompany() {
      this.overlay = true;
      this.progress = true;
      const form = new FormData();
      form.append("c_name", this.c_name);
      form.append("c_mobile", this.c_mobile);
      form.append("c_email", this.c_email);
      if (this.store_id.length > 1) {
        form.append("store_id", this.store_id);
      }
      const file = document.getElementById("company_logo_image").files[0];
      if (file) {
        form.append("image", file, file.name);
      }
      this.axios({
        url: this.$store.getters.get_api + "newShippingCompany",
        method: "post",
        data: form,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${this.$store.getters.get_token}`,
        },
      })
        .then((res) => {
          const data = res.data;
          this.loader = false;
          if (!data.error) {
            //this.overlay=true;
            this.progress = false;
            this.alert_box = true;
            this.alert_message = data.message;
            this.alert_type = "success";
            this.fetchCompany();
          } else {
            this.progress = false;
            this.alert_box = true;
            this.alert_message = data.message;
            this.alert_type = "error";
          }
        })
        .catch((error) => {
          this.progress = false;
          this.alert_box = true;
          this.alert_message = "Network Error";
          this.alert_type = "error";
          return error;
        });
    },
    selectImage() {
      document.getElementById("company_logo_image").click();
    },
    onImageSelected() {
      const file = document.getElementById("company_logo_image").files[0];
      const preview = document.getElementById("company_image_preview");
      const reader = new FileReader();
      reader.onload = (e) => {
        preview.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    switchMenu(name) {
      if (this.$router.currentRoute.path !== "/" + name.toLowerCase()) {
        this.$router.push("/" + name.toLowerCase());
      }
    },
  },
  created() {
    this.fetchCompany();
  },
  mounted() {
    this.sideMenu = this.$store.getters.get_menu;
    // this.getAllUsers(1)
    this.$store.dispatch("totalNotice");
    // this.$store.dispatch('totalTransaction')
  },
  watch: {
    //END OF WATCH
  },
};
</script>
<style scoped>
* {
  border: 0px;
}
.overflow-y-auto {
  min-width: 100%;
  min-height: 100%;
}
.icon-1 {
  font-size: 50px;
}
.c_cont {
  display: flex;
  justify-content: flex-start;
}
@media screen and (min-width: 640px) {
  .c_cont {
    margin: 3px;
    margin-left: 1em;
    flex-flow: row wrap;
  }
  .cont {
    margin: 5px;
  }
  .con {
    display: flex;
    flex-flow: row nowrap;
  }
  .gen-pwd_dialog {
    width: 300px;
  }
}
@media screen and (max-width: 600px) {
  .c_cont {
    margin: 2px;
    flex-flow: row wrap;
  }
  .cont {
    margin-top: 5px;
  }
  .form {
    width: 100%;
  }
  .con {
    display: flex;
    flex-flow: row wrap;
  }
  .gen-pwd_dialog {
    width: 90%;
  }
}

.b_cont {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.5);
  font-family: "roboto";
  margin-top: 3px;
}
.sm_r_box {
  width: 15px;
  height: 15px;
  background: #fe7b00;
  border-radius: 100%;
  margin-right: 2px;
}
.seller_box {
  padding: 6px;
  font-size: 15px;
}
.gen-pwd_dialog {
}
</style>